@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

html {
  scroll-behavior: smooth;
}

.otpVerification {
  border: 1px solid #ccc;
  border-radius: 0.325rem;
  height: 2.831rem !important;
  width: 2.831rem !important;
  font-size: 1rem !important;
  margin: 0 !important;
}

.display-flex {
  display: flex;
  justify-content: space-between;
}

.custom-input:focus {
  outline: 1.5px solid #96429e;
  border: none;
}

.drope_zone {
  border: dashed 1.5px #96429E !important;
  height: 8.75rem !important;
  min-width: 100% !important;
  max-width: 100% !important;
  border-radius: 0.625rem !important;
}

.drope_zone .file-types {
  display: none !important;
}

.drope_zone>svg {
  display: none !important;
}

.drope_zone>div>span {
  width: 100% !important;
  font-size: 1rem !important;
  word-wrap: break-word !important;
  text-align: center !important;
  margin-top: 3.5rem !important;
  padding: 0rem !important;
  height: 0% !important;
}

.drope_zone>div>span>span {
  text-decoration: none !important;
}

.upload_gst_drope_zone {
  border: dashed 1.5px #96429E !important;
  height: 11rem !important;
  min-width: 100% !important;
  max-width: 100% !important;
  border-radius: 0.625rem !important;
}

.upload_gst_drope_zone .file-types {
  display: none !important;
}

.upload_gst_drope_zone>svg {
  display: none !important;
}

.upload_gst_drope_zone>div>span {
  width: 100% !important;
  font-size: 1rem !important;
  word-wrap: break-word !important;
  text-align: center !important;
  margin-top: 3.5rem !important;
  padding: 0rem !important;
  height: 0% !important;
}

.upload_gst_drope_zone>div>span>span {
  text-decoration: none !important;
}

/* Hide the Google branding if permitted */
.goog-te-gadget img {
  display: none !important;
}

/* Style the dropdown */
/* #google_translate_element select {
  border: 1px solid #ccc;
  font-size: 14px;
  border-radius: .5rem;
  
}
.VIpgJd-ZVi9od-l4eHX-hSRGPd{
  display:none !important
}
.VIpgJd-ZVi9od-ORHb-OEVmcd{
  display:none !important;
}
.skiptranslate{
  margin-top: -29px !important;
} */

